import { VContext } from '~/types/types'
import { onError } from 'apollo-link-error'

export default function (context: VContext) {
  const error = context.app.nuxt.error

  const errorLink = onError(({ graphQLErrors }) => {
    let errorText = 'Something went wrong, please try again later'
    if (graphQLErrors && graphQLErrors.length) {

      context.app.$logger.error(
        new Error(graphQLErrors[0]?.extensions?.code),
        { ...graphQLErrors[0] }
      )

      const code = graphQLErrors[0]?.extensions?.code;
      if (code === "ENTITY_NOT_FOUND") {
        error({ statusCode: 404 });
        return
      }

      if (code === "VALIDATION_FAILED") {
        errorText = "Please fill out the form with correct values!"
        context.$snackbar(errorText);
        return;
      }
    }
    context.$snackbar(errorText);
    error({ statusCode: 500 });
  })

  return {
    link: errorLink,
    httpEndpoint: context.app.$config.gatewayUrl + '/graphql',
  }
}
