import Vue from 'vue';
import { ViralLogLevel } from '~/modules/viral-log/src/logging.constant';
import { VContext } from '~/types/types';
import { WinstonLoggerFactory } from '~/modules/viral-log/src/winston-logger';
import { Logger } from '~/modules/viral-log/src/logger';

export default (context: VContext, inject: Function): void => {
  const winstonLogger = new WinstonLoggerFactory().create({
    appLogLevel: context.$config.appLogLevel,
    appEnv: context.$config.appEnv,
    sentryDsn: context.$config.sentryDsn,
    releaseBranch: context.$config.releaseBranch,
  });

  const logger = new Logger(winstonLogger);

  Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
    logger.log(ViralLogLevel.Error, info);
  };

  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    logger.log(ViralLogLevel.Error, 'Logged in window.onunhandledrejection', { event });
  };

  window.onerror = (error) => {
    logger.log(ViralLogLevel.Error, error);
  };

  inject('logger', logger);
};
