import * as winston from 'winston';
import { AppEnvironment } from '~/constants/application';
import { ViralLogLevel } from '~/modules/viral-log/src/logging.constant';
import BrowserConsole from '~/modules/viral-log/src/transports/BrowserConsole';
import SentryTransport from '~/modules/viral-log/src/transports/SentryTransport';
import { LoggerConfig } from '~/modules/viral-log/src/types';

winston.configure({
  transports: [],
});

const _levels = {
  [ViralLogLevel.Error]: 0,
  [ViralLogLevel.Warn]: 1,
  [ViralLogLevel.Info]: 2,
  [ViralLogLevel.Http]: 3,
  [ViralLogLevel.Verbose]: 4,
  [ViralLogLevel.Debug]: 5,
  [ViralLogLevel.Silly]: 6,
};

export class WinstonLoggerFactory {
  create(config: LoggerConfig): winston.Logger {
    return winston.createLogger({
      levels: _levels,
      level: config.appLogLevel,
      format: winston.format.simple(),
      defaultMeta: {},
      transports: [
        new BrowserConsole({
          silent: config.appEnv === AppEnvironment.Production,
        }),
        new SentryTransport({
          sentryOpts: {
            dsn: config.sentryDsn,
            release: config.releaseBranch,
            attachStacktrace: true,
            enabled: [AppEnvironment.Review, AppEnvironment.Staging, AppEnvironment.Production].includes(config.appEnv),
            environment: config.appEnv,
          },
        }),
      ],
    });
  }
}
