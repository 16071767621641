






















































import {Vue, Component} from "vue-property-decorator";
import {DrawerLink} from "~/types/types";
import ViralFooter from "~/components/common/footer.vue";

@Component({
  components: {
    ViralFooter,
  }
})
export default class DefaultLayout extends Vue {
  snackbarStatus: boolean = false;
  snackbarText: string = "";
  drawer: boolean = false;

  links: DrawerLink[] = [
    {title: "About", path: "/intro#about"},
    {title: "Process", path: "/intro#process"},
    {title: "Why Us?", path: "/intro#why-us"},
    {title: "Make Money", path: "/intro#make-money"},
    {title: "Advantages", path: "/intro#advantages"},
    {title: "Help", path: "/intro#help"},
  ];

  created() {
    window.addEventListener("snackbar", e => {
      this.snackbarText = (e as CustomEvent).detail.text;
      this.snackbarStatus = true;
    });
  }
}
