











import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class AppDownloadLinks extends Vue {
  get googlePlayUrl(): string {
    return this.$config.googlePlayUrl;
  }

  get appStoreUrl(): string {
    return this.$config.appStoreUrl;
  }
}
