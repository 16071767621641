import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0001352b = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _12f34c98 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f8661eec = () => interopDefault(import('../pages/intro.vue' /* webpackChunkName: "pages/intro" */))
const _5e317626 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _6c9263b6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _0c07d50d = () => interopDefault(import('../pages/campaign/_id.vue' /* webpackChunkName: "pages/campaign/_id" */))
const _1fb90ff0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0001352b,
    name: "about"
  }, {
    path: "/faq",
    component: _12f34c98,
    name: "faq"
  }, {
    path: "/intro",
    component: _f8661eec,
    name: "intro"
  }, {
    path: "/privacy",
    component: _5e317626,
    name: "privacy"
  }, {
    path: "/terms",
    component: _6c9263b6,
    name: "terms"
  }, {
    path: "/campaign/:id?",
    component: _0c07d50d,
    name: "campaign-id"
  }, {
    path: "/",
    component: _1fb90ff0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
