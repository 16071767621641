














import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  layout: "empty",
})
export default class ErrorLayout extends Vue {
  pageNotFound: string = "404 Not Found";
  otherError: string = "Something went wrong, please try again later";

  @Prop() readonly error: any;
}
