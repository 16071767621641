

























































import { Component, Vue } from 'vue-property-decorator';
import AppDownloadLinks from '~/components/common/app-download-links.vue';

@Component({
  components: { AppDownloadLinks },
})
export default class ViralFooter extends Vue {
  socialLinks = [
    {
      label: 'Instagram',
      link: 'https://www.instagram.com/viral.deals/',
      icon: 'mdi-instagram',
    },
    {
      label: 'Twitter',
      link: 'https://twitter.com/viral_deals',
      icon: 'mdi-twitter',
    },
    {
      label: 'Youtube',
      link: 'https://www.youtube.com/channel/UCh07QoLc-2hV7fP5AMX5W-w',
      icon: 'mdi-youtube',
    },
    {
      label: 'Tiktok',
      link: 'https://www.tiktok.com/@viral.deals',
      icon: 'viral-icon-social-tiktok',
    },
    {
      label: 'Linkedin',
      link: 'https://www.linkedin.com/company/viral.deals',
      icon: 'mdi-linkedin',
    },
    {
      label: 'Facebook',
      link: 'https://www.facebook.com/profile.php?id=100087286167730',
      icon: 'mdi-facebook',
    },
  ];
  get googlePlayUrl(): string {
    return this.$config.googlePlayUrl;
  }

  get appStoreUrl(): string {
    return this.$config.appStoreUrl;
  }
}
