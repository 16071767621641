import createApp from './app.js'

import analyticsService from './service.analytics.js'

const appConfig = {"apiKey":"AIzaSyDbYuZLCMhUTljmmFKHdJuHUIpHW92vmig","authDomain":"viral-deals.firebaseapp.com","projectId":"viral-deals","storageBucket":"viral-deals.appspot.com","messagingSenderId":"626684456549","appId":"1:626684456549:web:b487bf60255d5d07ab608c","measurementId":"G-RY172EB95G"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.client) {
    servicePromises = [
      analyticsService(session, firebase, ctx, inject),

    ]
  }

  const [
    analytics
  ] = await Promise.all(servicePromises)

  const fire = {
    analytics: analytics
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}