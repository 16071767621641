import { VContext } from '~/types/types'

export default (context: VContext, inject: Function) => {
    inject('snackbar', (text: string) => {
        const event = new CustomEvent(
            'snackbar',
            { detail: { text } }
        );
        window.dispatchEvent(event)
    })
}