import Transport from 'winston-transport';
import * as Sentry from '@sentry/browser';
import { ViralLogLevel } from '~/modules/viral-log/src/logging.constant';

interface SentryTransportOptions extends Transport.TransportStreamOptions {
  sentryOpts: Sentry.BrowserOptions;
}

export default class SentryTransport extends Transport {
  private readonly levelMap;
  constructor(opts: SentryTransportOptions) {
    super(opts);

    if (opts.silent) return;

    Sentry.init(opts.sentryOpts);

    this.levelMap = {
      [ViralLogLevel.Error]: Sentry.Severity.Error,
      [ViralLogLevel.Warn]: Sentry.Severity.Warning,
      [ViralLogLevel.Info]: Sentry.Severity.Info,
      [ViralLogLevel.Verbose]: Sentry.Severity.Debug,
      [ViralLogLevel.Debug]: Sentry.Severity.Debug,
      [ViralLogLevel.Silly]: Sentry.Severity.Debug,
    };
  }

  log(info: { level: ViralLogLevel; message: any; context: any }): void {
    setImmediate(() => {
      this.emit('logged', info);
    });

    Sentry.captureException(info.message, info.context);
  }
}
