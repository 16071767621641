import Transport from 'winston-transport';
import { ViralLogLevel } from '~/modules/viral-log/src/logging.constant';

export default class BrowserConsole extends Transport {
  private readonly levelMap;
  constructor(opts: Transport.TransportStreamOptions) {
    super(opts);

    this.levelMap = {
      [ViralLogLevel.Error]: 'error',
      [ViralLogLevel.Warn]: 'warn',
      [ViralLogLevel.Info]: 'info',
      [ViralLogLevel.Http]: 'log',
      [ViralLogLevel.Debug]: 'debug',
      [ViralLogLevel.Verbose]: 'debug',
      [ViralLogLevel.Silly]: 'debug',
    };
  }

  log(info: { level: ViralLogLevel; message: any }, callback) {
    setImmediate(() => {
      this.emit('logged', info);
    });

    console[this.levelMap[info.level]](info.message);
  }
}
