// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/viral-icon.eot?v82rz4");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/viral-icon.ttf?v82rz4");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/viral-icon.woff?v82rz4");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/viral-icon.svg?v82rz4");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#viral-icon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"viral-icon\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:block}[class*=\" viral-icon-\"],[class^=viral-icon-]{font-family:\"viral-icon\"!important;speak:never;font-style:normal;font-weight:400;font-feature-settings:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.viral-icon-delimiter-active:before{content:\"\\e900\";color:#fff}.viral-icon-close:before{content:\"\\e901\"}.viral-icon-website:before{content:\"\\e902\"}.viral-icon-map:before{content:\"\\e903\"}.viral-icon-mail:before{content:\"\\e904\"}.viral-icon-call:before{content:\"\\e905\"}.viral-icon-briefcase:before{content:\"\\e906\"}.viral-icon-social-instagram:before{content:\"\\e907\"}.viral-icon-social-facebook:before{content:\"\\e908\"}.viral-icon-social-twitter:before{content:\"\\e909\"}.viral-icon-time:before{content:\"\\e90a\"}.viral-icon-settings:before{content:\"\\e90b\"}.viral-icon-statistics:before{content:\"\\e90c\"}.viral-icon-dashboard:before{content:\"\\e90d\"}.viral-icon-home:before{content:\"\\e90e\"}.viral-icon-social-apple:before{content:\"\\e90f\"}.viral-icon-social-google:before{content:\"\\e910\"}.viral-icon-social-facebook-square:before{content:\"\\e911\"}.viral-icon-social-website:before{content:\"\\e912\"}.viral-icon-social-youtube:before{content:\"\\e913\"}.viral-icon-social-tiktok:before{content:\"\\e914\"}.viral-icon-parking:before{content:\"\\e915\"}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
