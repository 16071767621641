import winston from 'winston';
import { ViralLogLevel } from '~/modules/viral-log/src/logging.constant';
import { LoggerType, User } from '~/modules/viral-log/src/types';

export class Logger implements LoggerType {
  constructor(private logger: winston.Logger) {}

  setUser(user: User): void {
    this.setTag('user', user);
  }

  setTag(tagKey: string, tagValue: any): void {
    this.logger.defaultMeta.context = { ...this.logger.defaultMeta.context, [tagKey]: tagValue };
  }

  log(logLevel: ViralLogLevel, message: any, ...optionalParams: any[]): void {
    this.logger.log(logLevel, message, optionalParams);
  }

  error(message: any, ...optionalParams: any[]): void {
    this.log(ViralLogLevel.Error, message, optionalParams);
  }
}
