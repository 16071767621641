import { required, email, min, regex } from "vee-validate/dist/rules";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import Vue from "vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("min", {
  ...min,
  message: "{_field_} must be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} must be of valid format",
});

extend("email", {
  ...email,
  message: "{_field_} must be valid",
});

extend('password', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
