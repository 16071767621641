






















































import {Vue, Component} from "vue-property-decorator";
import {DrawerLink} from "~/types/types";
import ViralFooter from "~/components/common/footer.vue";

@Component({
  components: {
    ViralFooter,
  }
})
export default class DefaultLayout extends Vue {
  snackbarStatus: boolean = false;
  snackbarText: string = "";
  drawer: boolean = false;

  links: DrawerLink[] = [
    {title: "About", path: "/about"},
    {title: "Features", path: "/#features"},
    {title: "For Creators", path: "/#for-creators"},
    {title: "For Advertisers", path: "/#for-advertisers"},
  ];

  created() {
    window.addEventListener("snackbar", e => {
      this.snackbarText = (e as CustomEvent).detail.text;
      this.snackbarStatus = true;
    });
  }
}
